import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import {
  Button,
  Typography,
  Box,
  IconButton,
  Tooltip,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  ContentCopy as CopyIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import Workspace from "../PageStructure/Workspace";
import ChartTargetSelector from "./ChartTargetSelector";
import ChartDateRangeSelector from "./ChartDateRangeSelector";
import ChartGraphWorkspace from "./ChartGraphWorkspace";
import ChartHeader from "./ChartHeader";
import ChartButtons from "./ChartButtons";
import {
  createChartLink,
  fetchChartLink,
  fetchMyLinks,
  fetchUserAccessibleCampuses,
  deleteChartLink,
} from "../actions/api";

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}`;

const Charts = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [sharedHeaderObject, setSharedHeaderObject] = useState({
    targetData: [],
    chartViewData: {},
  });
  const [targetSelection, setTargetSelection] = useState({
    selectedTargetCharts: [],
    selectedTargetKpis: [],
    selectedTargetId: null,
  });
  const [dateRangeSelection, setDateRangeSelection] = useState({
    startDate: null,
    startTime: null,
    startDateTime: null,
    endDate: null,
    endTime: null,
    endDateTime: null,
    rangeType: null,
  });
  const [linkMessage, setLinkMessage] = useState("");
  const [myLinks, setMyLinks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [linkLoading, setLinkLoading] = useState(false);
  const [showFavouriteLinks, setShowFavouriteLinks] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [campuses, setCampuses] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteLinkHashCode, setDeleteLinkHashCode] = useState(null);

  // Fetch campuses on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchUserAccessibleCampuses();
        if (data?.length > 0) {
          setCampuses(data);
          setSharedHeaderObject((prev) => ({
            ...prev,
            targetData: data,
          }));
        } else {
          setErrorMessage(t("No campuses available for this user."));
        }
      } catch (error) {
        setErrorMessage(t("Error fetching campuses data."));
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [t]);

  // Check for 'link' parameter in URL to load shared chart
  useEffect(() => {
    if (!isLoading) {
      // Ensure campuses data is loaded
      const query = new URLSearchParams(location.search);
      const linkHashCode = query.get("link");

      if (linkHashCode) {
        handleRetrieveLink(linkHashCode);
      }
    }
  }, [location.search, isLoading]); // Depend on isLoading

  const handleCreateLink = async () => {
    try {
      let chartType =
        targetSelection?.selectedTargetCharts?.[0]?.type || "Chart";

      const uniqueName = `${chartType} Summary - ${new Date().toLocaleString(
        "en-US",
        {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        }
      )}`;

      const linkData = {
        target_page: `${window.location.origin}/#/charts`, // Use window.location.origin
        page_data: {
          sharedHeaderObject,
          targetSelection,
          dateRangeSelection,
        },
        name: uniqueName,
      };

      const response = await createChartLink(linkData);
      if (response?.success) {
        const shareableLink = response.shareable_link;
        setLinkMessage(`Link created successfully: ${shareableLink}`);
        // Optionally copy the link to clipboard
        navigator.clipboard.writeText(shareableLink).then(() => {
          setLinkMessage(`Link copied to clipboard: ${shareableLink}`);
        });
      } else {
        setLinkMessage(
          "Failed to create the link. A similar link may already exist."
        );
      }

      setTimeout(() => {
        setLinkMessage("");
      }, 8000);
    } catch (error) {
      console.error("Error creating chart link:", error);
      setLinkMessage("Error occurred while creating the link.");

      setTimeout(() => {
        setLinkMessage("");
      }, 8000);
    }
  };

  const handleRetrieveLink = useCallback(
    async (hashCode, index = null) => {
      if (linkLoading) return;

      setLinkLoading(true);
      if (index !== null) {
        setSelectedIndex(index);
      }

      try {
        const response = await fetchChartLink(hashCode);
        if (response?.success) {
          const { page_data } = response.data;

          // Ensure that targetData includes campuses data
          setSharedHeaderObject(
            page_data?.sharedHeaderObject
              ? {
                  ...page_data.sharedHeaderObject,
                  targetData: campuses, // Ensure campuses data is included
                }
              : {
                  targetData: campuses,
                  chartViewData: {},
                }
          );

          setTargetSelection(
            page_data?.targetSelection || {
              selectedTargetCharts: [],
              selectedTargetKpis: [],
              selectedTargetId: null,
            }
          );
          setDateRangeSelection(
            page_data?.dateRangeSelection || {
              startDate: null,
              startTime: null,
              startDateTime: null,
              endDate: null,
              endTime: null,
              endDateTime: null,
              rangeType: null,
            }
          );
        } else {
          setLinkMessage("Failed to retrieve the link.");
        }
      } catch (error) {
        console.error("Error retrieving link:", error);
        setLinkMessage("Error occurred while retrieving the link.");
      } finally {
        setLinkLoading(false);

        setTimeout(() => {
          setLinkMessage("");
        }, 8000);
      }
    },
    [linkLoading, campuses]
  );

  const handleFetchMyLinks = async () => {
    try {
      if (showFavouriteLinks) {
        setShowFavouriteLinks(false);
        return;
      }

      const response = await fetchMyLinks();
      if (response?.success) {
        const sortedLinks = response.data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        setMyLinks(sortedLinks);
        setShowFavouriteLinks(true);
      } else {
        setLinkMessage("Failed to fetch your links.");

        setTimeout(() => {
          setLinkMessage("");
        }, 5000);
      }
    } catch (error) {
      console.error("Error fetching links:", error);
      setLinkMessage("Error occurred while fetching your links.");

      setTimeout(() => {
        setLinkMessage("");
      }, 5000);
    }
  };

  const handleDeleteLink = async () => {
    if (!deleteLinkHashCode) return;

    try {
      const response = await deleteChartLink(deleteLinkHashCode);
      if (response?.success) {
        setLinkMessage("Link deleted successfully.");
        setMyLinks(
          myLinks.filter((link) => link.hash_code !== deleteLinkHashCode)
        );
      } else {
        setLinkMessage("Failed to delete the link.");
      }
    } catch (error) {
      console.error("Error deleting link:", error);
      setLinkMessage("Error occurred while deleting the link.");
    }

    setDeleteDialogOpen(false);
    setDeleteLinkHashCode(null);

    setTimeout(() => {
      setLinkMessage("");
    }, 5000);
  };

  const handleCopyLink = (link) => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        setLinkMessage(`Link copied to clipboard: ${link}`);
      })
      .catch(() => {
        setLinkMessage("Failed to copy the link.");
      });

    setTimeout(() => {
      setLinkMessage("");
    }, 5000);
  };

  return (
    <Workspace isLoading={isLoading} title={t("CHARTS")}>
      {errorMessage ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 4,
          }}
        >
          <Card
            sx={{
              maxWidth: 400,
              textAlign: "center",
              borderRadius: "8px",
              boxShadow: 3,
            }}
          >
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2 }} color="textSecondary">
                {t("Information")}
              </Typography>
              <Typography variant="body1">{errorMessage}</Typography>
            </CardContent>
          </Card>
        </Box>
      ) : (
        <>
          <ChartHeader objectHandler={setSharedHeaderObject} />
          <ChartTargetSelector
            objectHandler={setTargetSelection}
            sharedHeaderObject={sharedHeaderObject}
            label={t("TARGET")}
          />
          <div
            style={{
              paddingTop: "1rem",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "left",
              flexWrap: "wrap",
            }}
          >
            <ChartDateRangeSelector objectHandler={setDateRangeSelection} />
            <ChartButtons
              sharedHeaderObject={sharedHeaderObject}
              targetSelection={targetSelection}
              dateRangeSelection={dateRangeSelection}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateLink}
              sx={{ ml: 2 }}
            >
              {t("Create Link")}
            </Button>
            <Button
              variant="contained"
              color="info"
              onClick={handleFetchMyLinks}
              sx={{ ml: 1 }}
            >
              {showFavouriteLinks
                ? t("Hide Favourite Links")
                : t("Favourite Links")}
            </Button>
          </div>

          {linkMessage && (
            <Typography variant="body1" color="success" sx={{ mt: 2 }}>
              {linkMessage}
            </Typography>
          )}

          {showFavouriteLinks &&
            Array.isArray(myLinks) &&
            myLinks.length > 0 && (
              <Box sx={{ mt: 2, display: "flex", flexWrap: "wrap" }}>
                <Typography
                  variant="h6"
                  sx={{ mb: 1, width: "100%", textAlign: "center" }}
                >
                  Favourite Links ({myLinks.length} links):
                </Typography>
                {myLinks.map((link, index) => {
                  const shareableLink = link.target_page;
                  return (
                    <Box
                      key={link.hash_code}
                      sx={{
                        backgroundColor:
                          selectedIndex === index ? "#e0e0e0" : "#f5f5f5",
                        borderRadius: "4px",
                        padding: "8px",
                        margin: "8px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "calc(33.33% - 16px)",
                        cursor:
                          linkLoading && selectedIndex !== index
                            ? "not-allowed"
                            : "pointer",
                        boxShadow:
                          selectedIndex === index
                            ? "0 0 5px rgba(0, 0, 0, 0.3)"
                            : "none",
                        pointerEvents:
                          linkLoading && selectedIndex !== index
                            ? "none"
                            : "auto",
                      }}
                      onClick={() => handleRetrieveLink(link.hash_code, index)}
                    >
                      <Typography variant="body1" noWrap>
                        {link.name || link.hash_code}
                      </Typography>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Tooltip title="Copy link">
                          <IconButton
                            size="small"
                            color="primary"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCopyLink(shareableLink);
                            }}
                          >
                            <CopyIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete link">
                          <IconButton
                            size="small"
                            color="error"
                            onClick={(e) => {
                              e.stopPropagation();
                              setDeleteDialogOpen(true);
                              setDeleteLinkHashCode(link.hash_code);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </Box>
                  );
                })}
              </Box>
            )}

          <Dialog
            open={deleteDialogOpen}
            onClose={() => setDeleteDialogOpen(false)}
          >
            <DialogTitle>{t("Confirm Deletion")}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t(
                  "Are you sure you want to delete this link? This action cannot be undone."
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setDeleteDialogOpen(false)}
                color="primary"
              >
                {t("Cancel")}
              </Button>
              <Button
                onClick={handleDeleteLink}
                color="error" // Updated to red/danger
                variant="contained" // Make the delete button more prominent
              >
                {t("Delete")}
              </Button>
            </DialogActions>
          </Dialog>

          <ChartGraphWorkspace
            sharedHeaderObject={sharedHeaderObject}
            targetSelection={targetSelection}
            dateRangeSelection={dateRangeSelection}
          />
        </>
      )}
    </Workspace>
  );
};

export default Charts;
