import React from "react";
import { useNavigate } from "react-router-dom";
import {
   MenuItem,  
    ListItemIcon,
} from "@mui/material";
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import { useTranslation } from "react-i18next";



const ColorSettings = ({ handleClose }) => {
    const navigate = useNavigate();
    const {  t } = useTranslation();


    return (
        <MenuItem
            sx={{ marginBottom: "1rem" }}
            onClick={() => {
                navigate("/theme");
                handleClose()
              }}
        >
            
            <div style={{ display: "flex", alignItems: "center" }}>
            <ListItemIcon>
              <FormatColorFillIcon />
            </ListItemIcon>
            {t("COLORS")}
          </div>
            
        </MenuItem >
    )

}

export default ColorSettings;