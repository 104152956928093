import React, { useState } from "react";
import Button from '@mui/material/Button';
import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';
import { useNavigate } from 'react-router-dom';


// input: points={{changeHandler: handlePointChange, defaultVal:chartObject.selectedPointIds, allVal:chartObject.pointsData}}
export default function ChartSaveButton(props) {
    const { sharedHeaderObject,targetSelection,dateRangeSelection } = props

    const navigate = useNavigate()
    const isEnabled = (sharedHeaderObject.chartViewData && targetSelection.selectedTargetId && dateRangeSelection.startDate) ? true : false

    // Handlers
    // Handle all clicks on buttons
    const handleClick = (event,obj) => {
        navigate(`/charts/favorites`, { state: obj });
    };
   
    return (
        <>
            <Button
                id="save-button"
                disabled={!isEnabled}
                onClick={(e)=> handleClick(e, {sharedHeaderObject,targetSelection,dateRangeSelection})}
            >
                <SaveAsOutlinedIcon />
            </Button>
            
        </>
    );
}