import React from "react";
import Stack from '@mui/material/Stack';
import { useDrawerWidth } from '../../size';
import ChartSaveButton from "./ChartSaveButton"


export default function ChartButtons(props) {

    const drawerWidth = useDrawerWidth()
    // Handle actions
    
   
    // activate button only, of all data is valid
    return (
        <div style={{ paddingTop: "1rem", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: drawerWidth === 0 ? 'space-between' : 'left', flexWrap: 'wrap' }}>
            <div style={{ paddingLeft: "0.5rem", paddingBottom: "1rem", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                <Stack
                    direction="row"
                    spacing={0.5} >
                   
                    <ChartSaveButton {...props} />
                </Stack>
            </div>
        </div>
    );
}