import i18next from './i18n';

// constants.js

const headerHeight = 60

export const HEADER_HEIGHT = `${headerHeight}px`
export const SIDEBAR_WIDTH_SM = headerHeight; // px
export const SIDEBAR_WIDTH_MD = headerHeight; // px
export const SIDEBAR_WIDTH_LG = headerHeight; // px
export const SIDEBAR_WIDTH_XL = headerHeight; // px

export const TASK_GROUPS = [
    { text: i18next.t('VENTILATION'), key: "VENTILATION", api: 'Ventilation' },
    { text: i18next.t('HEATING_LOOPS'), key: "HEATING_LOOPS", api: 'Heating Loops' },
    { text: i18next.t('ROOM_CONDITIONING'), key: "ROOM_CONDITIONING", api: 'Room Conditioning' },
    { text: i18next.t('DOMESTIC_WATER'), key: "DOMESTIC_WATER", api: 'Domestic Water' },
    { text: i18next.t('COOLING'), key: "COOLING", api: 'Cooling' },
    { text: i18next.t('COOLING_LOOPS'), key: "COOLING_LOOPS", api: 'Cooling Loops' },
    { text: i18next.t('DEFICIENCIES'), key: "DEFICIENCIES", api: 'Deficiencies' },
    { text: i18next.t('NOTES'), key: "NOTES", api: 'Notes' },
    { text: i18next.t('REPLACEMENT'), key: "REPLACEMENT", api: 'Replacement' },
    { text: i18next.t('OTHERS'), key: "OTHERS", api: 'Others' },
];

export const TASK_PRIOS = [
    { text: i18next.t('HIGH'), key: "HIGH", api: 'High' },
    { text: i18next.t('MEDIUM'), key: "MEDIUM", api: 'Medium' },
    { text: i18next.t('LOW'), key: "LOW", api: 'Low' },
];
export const TASK_STATES = [
    { text: i18next.t('OPEN'), key: "OPEN", api: 'Open' },
    { text: i18next.t('INPROGRESS'), key: "INPROGRESS", api: 'In-Progress' },
    { text: i18next.t('DONE'), key: "DONE", api: 'Done' },
    { text: i18next.t('CANCEL'), key: "CANCEL", api: 'Cancel' },
    { text: i18next.t('REOPEN'), key: "REOPEN", api: 'Re-Open' },
];
export const UPLOT_DAYS = [
    { text: i18next.t('SUNDAY'), key: "SUNDAY" },
    { text: i18next.t('MONDAY'), key: "MONDAY" },
    { text: i18next.t('TUESDAY'), key: "TUESDAY" },
    { text: i18next.t('WEDNESDAY'), key: "WEDNESDAY" },
    { text: i18next.t('THURSDAY'), key: "THURSDAY" },
    { text: i18next.t('FRIDAY'), key: "FRIDAY" },
    { text: i18next.t('SATURDAY'), key: "SATURDAY" },
];
export const UPLOT_DAYS_SHORT = [
    { text: i18next.t('SUN'), key: "SUN" },
    { text: i18next.t('MON'), key: "MON" },
    { text: i18next.t('TUE'), key: "TUE" },
    { text: i18next.t('WED'), key: "WED" },
    { text: i18next.t('THU'), key: "THU" },
    { text: i18next.t('FRI'), key: "FRI" },
    { text: i18next.t('SAT'), key: "SAT" },
];
export const UPLOT_MONTHS_SHORT = [
    { text: i18next.t('JAN'), key: "JAN" },
    { text: i18next.t('FEB'), key: "FEB" },
    { text: i18next.t('MAR'), key: "MAR" },
    { text: i18next.t('APR'), key: "APR" },
    { text: i18next.t('MAI'), key: "MAI" },
    { text: i18next.t('JUN'), key: "JUN" },
    { text: i18next.t('JUL'), key: "JUL" },
    { text: i18next.t('AUG'), key: "AUG" },
    { text: i18next.t('SEP'), key: "SEP" },
    { text: i18next.t('OCT'), key: "OCT" },
    { text: i18next.t('NOV'), key: "NOV" },
    { text: i18next.t('DEC'), key: "DEC" },
];

export const DATERANGES = [
    {
        key: "THIS_DAY",
        value: "this_day",
        id: "this_day",
        selected: false
    },
    {
        key: "THIS_WEEK",
        value: "this_week",
        id: "this_week",
        selected: false
    },
    {
        key: "THIS_MONTH",
        value: "this_month",
        id: "this_month",
        selected: false
    },
    {
        key: "THIS_QUARTER",
        value: "this_quarter",
        id: "this_quarter",
        selected: false
    },
    {
        key: "THIS_YEAR",
        value: "this_year",
        id: "this_year",
        selected: false
    },
    {
        key: "LAST_DAY",
        value: "last_day",
        id: "last_day",
        selected: false
    },
    {
        key: "LAST_WEEK",
        value: "last_week",
        id: "last_week",
        selected: false
    },
    {
        key: "LAST_MONTH",
        value: "last_month",
        id: "molast_monthnthly",
        selected: false
    },
    {
        key: "LAST_QUARTER",
        value: "last_quarter",
        id: "last_quarter",
        selected: false
    },
    {
        key: "LAST_YEAR",
        value: "last_year",
        id: "last_year",
        selected: false
    },
    {
        key: "CUSTOM",
        value: "custom",
        id: "custom",
        selected: true
    },
]
/**
 * Iterate the array and translate each key to the text key.
 * @param {Array} range - An array from constants.js
*  */
export const hotTranslate = (range) => {
    return range.map(state => ({
        ...state,
        text: i18next.t(`${state.key}`),
    }));
};