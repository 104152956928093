// api.js
import axios from "axios";
import { unpack } from "msgpackr/unpack";

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}`;

// Function to get headers with the current token
const getHeaders = () => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
};

/**
 * Carbone API Call: Prepare PDF.
 * See https://carbone.io/api-reference.html#carbone-cloud-api
 * @return {Object} Data
 */
const createPdf = async (data) => {
  try {
    throw Error("API not implemented");
  } catch (error) {
    console.error("Error fetching pdf from carbone:", error, data);
    return { campuses: [] };
  }
};

/**
 * Backend Call: Get all campuses accessible to the user.
 * @return {Object} Data
 */
const fetchUserAccessibleCampuses = async () => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/customers/user-accessible-campuses/`,
      getHeaders()
    );
    return response.data; // Your successful campus data
  } catch (error) {
    console.error("Error fetching campuses:", error);
    return null;
  }
};

/**
 * Backend Call: Get customer accessible campuses.
 * @return {Object} Data
 */
const fetchCustomerAccessibleCampus = async () => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/customers/customer/`,
      getHeaders()
    );
    return response.data && response.data.results.length > 0
      ? response.data.results[0]
      : { campuses: [] };
  } catch (error) {
    console.error("Error fetching customer-accessible campuses:", error);
    return { campuses: [] };
  }
};

/**
 * Backend Call: Get tasks by query.
 * @param {String} queryString - Query: campus_id=?, task_group=?
 * @return {Array} Data
 */
const fetchTasksWithQuery = async (queryString) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/customers/tasks/${queryString}`,
      getHeaders()
    );
    return response.data ? response.data.results : [];
  } catch (error) {
    console.error("Error fetching tasks by query:", error);
    return [];
  }
};

/**
 * Backend Call: Post a new task.
 * @param {Object} data - Object with new data.
 * Contains: title=String, due_date=String (DateTime), priority=String, status=String, task_group=String, assigned_to=?, campus=Number
 * @return {Boolean} True if successful, false if not
 */
const postNewTasks = async (data) => {
  try {
    const response = await axios.post(
      `${baseUrl}/api/customers/campuses/${data.campus}/tasks/`,
      data,
      getHeaders()
    );
    return response.status === 201;
  } catch (error) {
    console.error("Error adding new tasks:", error);
    return false;
  }
};

/**
 * Backend Call: Get a task's comments by task ID.
 * @param {Number} id - Task id
 * @return {Object} Data
 */
const fetchTaskComments = async (id) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/customers/tasks/${id}`,
      getHeaders()
    );
    return response.data ? response.data : null;
  } catch (error) {
    console.error(`Error fetching comment from task ${id}:`, error);
    return null;
  }
};

/**
 * Backend Call: Post a new task comment.
 * @param {Object} data - Object with new data.
 * Contains: text=String, task=Number (task id)
 * @return {Object} Created comment data
 */
const postTaskComment = async (data) => {
  try {
    const response = await axios.post(
      `${baseUrl}/api/customers/tasks/${data.task}/comments/`,
      data,
      getHeaders()
    );
    if (response.data) return response.data;
    else throw Error("Wrong response format");
  } catch (error) {
    console.error("Error adding new task comment:", error);
    return false;
  }
};

/**
 * Backend Call: Patch an existing task comment.
 * @param {Object} data - Object with updated data.
 * @param {Number} commentId - Comment id
 * @return {Object} Updated comment data
 */
const patchTaskComment = async (data, commentId) => {
  try {
    const response = await axios.patch(
      `${baseUrl}/api/customers/tasks/${data.task}/comments/${commentId}/`,
      data,
      getHeaders()
    );
    if (response.data) return response.data;
    else throw Error("Wrong response format");
  } catch (error) {
    console.error("Error patching task comment:", error);
    return false;
  }
};

/**
 * Backend Call: Delete a task comment.
 * @param {Number} taskId - Task id
 * @param {Number} commentId - Comment id
 * @return {Boolean} True if successful, false otherwise
 */
const deleteTaskComment = async (taskId, commentId) => {
  try {
    const response = await axios.delete(
      `${baseUrl}/api/customers/tasks/${taskId}/comments/${commentId}/delete/`,
      getHeaders()
    );
    return response.status === 204;
  } catch (error) {
    console.error("Error deleting task comment:", error);
    return false;
  }
};

/**
 * Backend Call: Get users assigned to a campus.
 * @param {String} campusId - Id of campus
 * @return {Array} Assigned users
 */
const fetchCampusAssignedUsers = async (campusId) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/customers/campuses/${campusId}/`,
      getHeaders()
    );
    return response.data ? response.data.assigned_users : [];
  } catch (error) {
    console.error("Error fetching users assigned to campus:", error);
    return [];
  }
};

/**
 * Backend Call: Get task by id.
 * @param {String} id - Task id
 * @return {Object} Task data
 */
const fetchTaskById = async (id) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/customers/tasks/${id}`,
      getHeaders()
    );
    return response.data ? response.data : null;
  } catch (error) {
    console.error("Error fetching tasks by id:", error);
    return null;
  }
};

/**
 * Backend Call: Delete a task by id.
 * @param {String} id - Task id
 * @return {Boolean} True if successful, false otherwise
 */
const deleteTask = async (id) => {
  try {
    const response = await axios.delete(
      `${baseUrl}/api/customers/tasks/${id}/`,
      getHeaders()
    );
    return response.status === 204;
  } catch (error) {
    console.error("Error deleting task:", error);
    return false;
  }
};

/**
 * Backend Call: Patch task attributes.
 * @param {String} id - Task id
 * @param {Object} data - Object with updated values.
 * @return {Boolean} True if successful, false otherwise
 */
const patchTaskMetaData = async (id, data) => {
  try {
    const response = await axios.patch(
      `${baseUrl}/api/customers/tasks/${id}/`,
      data,
      getHeaders()
    );
    return response.status === 200;
  } catch (error) {
    console.error("Error patching tasks by id:", error);
    return false;
  }
};

// Fetch chart link data by hash code
export const fetchChartLinkData = async (hashCode) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/customers/links/${hashCode}/`,
      getHeaders()
    );
    return { success: true, data: response.data };
  } catch (error) {
    return {
      success: false,
      data: `Error retrieving chart link: ${error.message}`,
    };
  }
};

// Create chart link
export const createChartLink = async (linkData) => {
  try {
    const response = await axios.post(
      `${baseUrl}/api/customers/create-link/`,
      linkData,
      getHeaders()
    );
    return {
      success: true,
      hash_code: response.data.hash_code,
      shareable_link: response.data.shareable_link,
    };
  } catch (error) {
    console.error("Error creating chart link:", error);
    return { success: false };
  }
};

// Retrieve a specific link by hash code
export const fetchChartLink = async (hashCode) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/customers/links/${hashCode}/`,
      getHeaders()
    );
    return { success: true, data: response.data };
  } catch (error) {
    console.error("Error retrieving chart link:", error);
    return { success: false };
  }
};

// Fetch all links created by the user
export const fetchMyLinks = async () => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/customers/my-links/?limit=1000`,
      getHeaders()
    );
    return { success: true, data: response.data.results };
  } catch (error) {
    console.error("Error fetching my links:", error);
    return { success: false };
  }
};

// Fetch all links (paginated)
export const fetchAllLinks = async () => {
  let allLinks = [];
  let currentPage = 1;
  let hasNextPage = true;

  while (hasNextPage) {
    try {
      const response = await axios.get(
        `${baseUrl}/api/customers/my-links/?page=${currentPage}`,
        getHeaders()
      );

      if (response?.data?.results) {
        allLinks = [...allLinks, ...response.data.results];
        if (response.data.next) {
          currentPage += 1;
        } else {
          hasNextPage = false;
        }
      } else {
        hasNextPage = false;
      }
    } catch (error) {
      console.error("Error fetching links:", error);
      hasNextPage = false;
    }
  }

  return allLinks;
};

// Delete a specific link by hash code
export const deleteChartLink = async (hashCode) => {
  try {
    const response = await axios.delete(
      `${baseUrl}/api/customers/links/${hashCode}/delete/`,
      getHeaders()
    );
    return { success: true };
  } catch (error) {
    console.error("Error deleting chart link:", error);
    return { success: false };
  }
};

/**
 * Backend Call: Get all chart descriptions (markdown).
 * @param {String} customerID - Database id of customer
 * @param {String} selectedCampus - Database id of campus
 * @return {Array} Chart descriptions
 */
const fetchChartDescription = async (customerID, selectedCampus) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/customers/customer/${customerID}/campuses/${selectedCampus}/get_charts`,
      getHeaders()
    );
    return response.data ? response.data.results : [];
  } catch (error) {
    console.error("Error fetching chart descriptions:", error);
    return [];
  }
};

/**
 * Model Server Call: Get all chart view templates.
 * @param {String} customerID - Database id of customer
 * @param {String} selectedCampus - Database id of campus
 * @return {Array} Chart views
 */
export const fetchChartViews = async (customerID, selectedCampus) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/customers/customer/${customerID}/campuses/${selectedCampus}/oxoia_getChartViews`,
      getHeaders()
    );

    if (response.data.length > 0) {
      console.log("API response data:", response.data); // Log the received data
      return response.data;
    } else {
      console.error(
        "Error on model server fetching chart data:",
        response.data.error
      );
      return [];
    }
  } catch (error) {
    console.error("Error fetching chart views:", error);
    return [];
  }
};

/**
 * Model Server Call: Get targets from the model.
 * @param {String} customerID - Database id of customer
 * @param {String} selectedCampus - Database id of campus
 * @param {String} command - Command string
 * @return {Array} Targets
 */
const fetchChartTargets = async (customerID, selectedCampus, command) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/customers/customer/${customerID}/campuses/${selectedCampus}/oxoia_getChartTarget/?oxoia_command=${encodeURIComponent(
        command
      )}`,
      getHeaders()
    );

    if (response.data.length >= 0) {
      return response.data;
    } else {
      console.info(
        "Error on model server fetching chart targets:",
        response.data.error
      );
      return [];
    }
  } catch (error) {
    console.error("Error fetching chart targets:", error);
    return [];
  }
};

/**
 * Model Server Call: Get all points linked to the selected target.
 * @param {String} customerID - Database id of customer
 * @param {String} selectedCampus - Database id of campus
 * @param {String} targetId - Haystack id of target
 * @param {String} chartSpec - Spec id of chart
 * @param {String} functionName - Function name
 * @return {Object} Chart points data
 */
const fetchChartPoints = async (
  customerID,
  selectedCampus,
  targetId,
  chartSpec,
  functionName
) => {
  try {
    if (!targetId || !chartSpec || !functionName)
      throw Error(
        "Received undefined input argument 'targetId', 'chartSpec', or 'functionName'."
      );

    const response = await axios.get(
      `${baseUrl}/api/customers/customer/${customerID}/campuses/${selectedCampus}/oxoia_getChartPoints/?chart_id=${targetId}&spec=${chartSpec}&function_name=${functionName}`,
      getHeaders()
    );

    return response.data[0].data; // data is a list
  } catch (error) {
    console.error("Error fetching chart points:", error);
    return {};
  }
};

/**
 * Fetch time series data for charts.
 * @param {Array} listOfIds - Array of sensor IDs
 * @param {Array} listOfModes - Array of modes
 * @param {String} start - Start time
 * @param {String} end - End time
 * @param {String} lib - Library (unused in this function)
 * @return {Object} Time series data
 */
const fetchChartTimeSeriesData = async (
  listOfIds,
  listOfModes,
  start,
  end,
  lib
) => {
  try {
    if (listOfIds.length === 0) return { data: [] };
    // IDs in database without '@'
    const cleanedArray = listOfIds.map((item) => item.replace(/@/g, ""));
    const data = {
      sensor_ids: cleanedArray,
      start: start,
      end: end,
      hisMode: listOfModes,
    };

    console.time(`Duration data call with msgpack ${listOfIds}`);
    let serializedAsBuffer = await axios.post(
      baseUrl + "/api/customers/sensor-data-msgpack/",
      data,
      {
        responseType: "arraybuffer",
        headers: {
          ...getHeaders().headers,
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      }
    );

    const response = unpack(new Uint8Array(serializedAsBuffer.data));
    console.timeEnd(`Duration data call with msgpack ${listOfIds}`);
    console.log("Data received:", data, response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching chart time series data:", error);
    return [];
  }
};

// GENERIC API CALL FUNCTIONS
const apiCaller = async (path) => {
  try {
    const response = await axios.get(baseUrl + path, getHeaders());
    return response.data;
  } catch (error) {
    console.error(`Error fetching data from ${path}:`, error);
    throw error;
  }
};

const apiPatcher = async (path, data) => {
  try {
    const response = await axios.patch(baseUrl + path, data, getHeaders());
    return response;
  } catch (error) {
    console.error("Error patching data:", "Path:", path, "Error:", error);
    throw error;
  }
};

const apiDeleter = async (path) => {
  try {
    const response = await axios.delete(baseUrl + path, getHeaders());
    return response;
  } catch (error) {
    console.error("Error deleting data:", "Path:", path, "Error:", error);
    throw error;
  }
};

const apiPoster = async (path, data) => {
  try {
    const response = await axios.post(baseUrl + path, data, getHeaders());
    return response;
  } catch (error) {
    console.error("Error posting data:", "Path:", path, "Error:", error);
    throw error;
  }
};

export {
  createPdf,
  fetchUserAccessibleCampuses,
  fetchTasksWithQuery,
  fetchTaskById,
  fetchCampusAssignedUsers,
  deleteTask,
  postNewTasks,
  patchTaskMetaData,
  fetchTaskComments,
  postTaskComment,
  patchTaskComment,
  deleteTaskComment,
  fetchChartDescription,
  fetchChartTimeSeriesData,
  fetchCustomerAccessibleCampus,
  fetchChartTargets,
  fetchChartPoints,
};
