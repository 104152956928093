import React, { useState, useEffect } from "react";
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { useDrawerWidth } from '../../size';
import {
    fetchChartPoints,
} from "../actions/api";


// Select target based on the model by clicking on a chip. The chips are sorted alphabetically. For small screens, a selector is provided
const ChartTargetSelector = ({ sharedHeaderObject, label, objectHandler, value }) => {

    const drawerWidth = useDrawerWidth()
    const [target, setTarget] = useState(() => {
        const saved = localStorage.getItem("chartTarget");
        return saved === null || saved.length === 0
            ? {
                selectedTargetId: null,
                selected: null,
                selectionName:null,
                selectedTargetCharts: [],
                selectedTargetKpis: [],
            }
            : JSON.parse(saved)
    });


    const [isLoading, setIsLoading] = useState(false);


    // reset internal states based on inputs
    useEffect(() => {
        if (sharedHeaderObject && sharedHeaderObject.targetData.length === 0) {
            setTarget({
                selectedTargetId: null,
                selected: null,
                selectionName:null,
                selectedTargetCharts: [],
                selectedTargetKpis: [],
            })
            objectHandler({ selectedTargetCharts: null, selectedTargetKpis: null, selectedTargetId: null, selectionName:null })
        }

    }, [sharedHeaderObject, objectHandler]);

    // wait for updated data and then trigger objectHandler
    useEffect(() => {
        if (!isLoading) {
            // update data after all promises are resolved
            objectHandler({ selectedTargetCharts: target.selectedTargetCharts, selectedTargetKpis: target.selectedTargetKpis, selectedTargetId: target.selectedTargetId, selectionName: target.selectionName });
        }
    }, [target, objectHandler, isLoading]);

    // Api call
    const mergeData = (data) => {
        const mergedObject = data.reduce((acc, curr) => {
            // Merge properties
            for (const key in curr) {
                if (Array.isArray(curr[key])) {
                    // If the key exists in the accumulator, concatenate the arrays
                    if (acc[key]) {
                        acc[key] = acc[key].concat(curr[key]);
                    } else {
                        acc[key] = curr[key];
                    }
                } else {
                    // For non-array properties, just take the value from the first object
                    if (!acc[key]) {
                        acc[key] = curr[key];
                    }
                }
            }
            return acc;
        }, {});
        return mergedObject
    }

    const getChartPointsFromApi = async (chartElement, index, targetId, functionName, dataArray) => {
        let data = await fetchChartPoints(
            sharedHeaderObject.chartViewData.customerID,
            sharedHeaderObject.chartViewData.campusId,
            targetId,
            chartElement.spec,
            functionName
        );
        if (!Array.isArray(data) || data.length === 0) {

            const prevCharts = sharedHeaderObject.chartViewData.charts[index]
            dataArray.push(prevCharts)
            return;
        }
        // data is an array with an entry for each series in the chart. As all series are shown in the same chart, they must be combined to an object
        // with all values. the index 
        const dataObj = (data.length > 1) ? mergeData(data) : data[0]

        const chartEntry = {
            ...sharedHeaderObject.chartViewData.charts[index],
            title: chartElement.dis,
            spec: chartElement.spec,
            targetId: targetId,
            dataSeries: dataObj,
            colors: null, // remove
            seriesTypes: null, // remove
        };
        dataArray.push(chartEntry)
        return Promise.resolve()
    };


    const handleChange = async (selection, index) => {
        setIsLoading(true);


        const chartData = [];
        try {
            const chartPromises = sharedHeaderObject.chartViewData.charts.map((element, index) => {
                return getChartPointsFromApi(
                    element,
                    index,
                    selection.id,
                    sharedHeaderObject.chartViewData.searchFunc, // Assuming this is correct
                    chartData
                );
            });
            await Promise.all(chartPromises);
            setTarget((prevTarget) => ({
                ...prevTarget,
                selectedTargetId: selection.id,
                selectionName: selection.navName, // only for testing
                selected: index,
                selectedTargetCharts: chartData
            }));
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    // return if no data
    if (!sharedHeaderObject || ((!sharedHeaderObject.targetData || sharedHeaderObject.targetData.length === 0) && (value === undefined || value === null))) return null




    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
            }}
        >
            <Stack direction="row" spacing={1} sx={{ paddingRight: '2rem', paddingTop: drawerWidth === 0 ? '0' : '1rem', paddingBottom: drawerWidth === 0 ? '0' : '1rem' }} useFlexGap flexWrap="wrap">
                {sharedHeaderObject.targetData.map((obj, index) => (
                    <Chip
                        className={drawerWidth === 0 ? 'mobile' : null}
                        key={index}
                        clickable={true}
                        sx={{
                            '& .MuiChip-label': {
                                fontSize: drawerWidth === 0 ? '0.875rem' : '1.2rem',
                                paddingTop: drawerWidth === 0 ? '1px' : '2px',

                            },
                        }}
                        style={{
                            height: drawerWidth === 0 ? '20px' : 'auto',
                        }}
                        color={(index === target.selected) ? "primary" : "default"}
                        label={obj.navName}
                        onClick={() => handleChange(obj, index)} />
                ))}
            </Stack>
        </div>
    )
    // }
}

export default ChartTargetSelector