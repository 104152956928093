import React, { useEffect, useState, useCallback } from "react";
import ChartBlock from "./ChartBlock";
import "react-datepicker/dist/react-datepicker.css"; // Import CSS
import { CircularProgress, Box, Typography } from "@mui/material";

import { useDrawerWidth } from "../../size";
import {
  fetchChartDescription,
  fetchChartTimeSeriesData,
} from "../actions/api";
import { getChartBlockSizes } from "./chartFuncs";

// Debounce function
function debounce(func, delay) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), delay);
  };
}

/**
 * Component defining the view section resolution of a chart page.
 * @param {Object} sharedHeaderObject - Object with campus and chart selection data.
 * @param {Object} targetSelection - Object with target selection data
 * @param {Object} dateRangeSelection - Object with date range data.
 */
const ChartGraphWorkspace = ({
  sharedHeaderObject,
  targetSelection,
  dateRangeSelection,
}) => {
  const { selectedTargetId, selectedTargetCharts } = targetSelection;
  const { startDateTime, endDateTime } = dateRangeSelection;
  const { chartViewData } = sharedHeaderObject;
  const { customerID, campusId } = chartViewData;

  const idContainer = "graphContainer";

  const drawerWidth = useDrawerWidth(); // if 0 => mobile
  const boxWidth = drawerWidth === 0 ? "100%" : "100%";

  const [dataSets, setDataSets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [blockSize, setBlockSize] = useState([]);
  const [chartDescriptions, setChartDescriptions] = useState([]); // TODO

  const handleResize = useCallback(() => {
    if (!selectedTargetCharts) return;

    try {
      const sizes = selectedTargetCharts
        .sort((a, b) => a.index - b.index)
        .map((chart) =>
          getChartBlockSizes(idContainer, drawerWidth, chart.dataSeries)
        );

      setBlockSize(sizes);
    } catch (error) {
      console.error("Check the sizeDefiningId", error);
    }
  }, [idContainer, drawerWidth, selectedTargetCharts]);

  // Resize all components
  useEffect(() => {
    handleResize(); // Initial call to set sizes

    const debouncedResize = debounce(handleResize, 100);
    window.addEventListener("resize", debouncedResize);

    return () => {
      window.removeEventListener("resize", debouncedResize);
    };
  }, [handleResize]);

  // Load chart templates
  useEffect(() => {
    if (customerID && campusId) {
      const fetchData = async () => {
        const newDescriptions = await fetchChartDescription(
          customerID,
          campusId
        );
        setChartDescriptions(newDescriptions);
      };

      fetchData();
    }
  }, [customerID, campusId]);

  // Store data sets
  useEffect(() => {
    if (chartViewData.length === 0) {
      setDataSets([]);
    }
  }, [chartViewData]);

  useEffect(() => {
    // Data is in right format
    const fetchData = async (
      _idList,
      _modelist,
      _startDateTime,
      _endDateTime
    ) => {
      try {
        const data = await fetchChartTimeSeriesData(
          _idList,
          _modelist,
          _startDateTime,
          _endDateTime,
          "uplot"
        );

        return data;
      } catch (error) {
        console.error("ChartGraphWorkspace: Error fetching data:", error);
        return [];
      }
    };

    const updateDataSets = async (_charts, _start, _end) => {
      const promises = _charts.map(async (_chart) => {
        if (_chart.dataSeries) {
          const pointList = _chart.dataSeries.points;
          const modeList = _chart.dataSeries.hisMode;
          return await fetchData(pointList, modeList, _start, _end);
        } else return [];
      });

      try {
        setLoading(true);
        const newDataSetValues = await Promise.all(promises);
        // combine all data sets
        setDataSets(newDataSetValues);
      } catch (error) {
        console.error("Error updating data sets:", error);
      } finally {
        setLoading(false);
      }
    };

    if (selectedTargetCharts && startDateTime) {
      // clear datasets first
      setDataSets([]);
      updateDataSets(selectedTargetCharts, startDateTime, endDateTime);
    } else {
      setDataSets([]);
    }
  }, [selectedTargetCharts, startDateTime, endDateTime]);

  // TODO Translatios
  // Early return for missing selections, important width
  if (selectedTargetId === null || startDateTime === null) {
    return (
      <div id={idContainer} style={{ width: boxWidth }}>
        Wähle Thema, Zielgrösse und Datumbereich
      </div>
    );
  }

  // Early return for loading state, important width
  if (loading || dataSets.length === 0) {
    return (
      <Box
        id={idContainer}
        sx={{
          width: boxWidth,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          padding: "16px",
        }}
      >
        <CircularProgress color="primary" />
        <Typography variant="body1" sx={{ mt: 2 }}>
          Loading all data sets...
        </Typography>
      </Box>
    );
  }

  // Main rendering logic
  return (
    <div
      id={idContainer}
      style={{
        width: boxWidth,
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "left",
      }}
    >
      {selectedTargetCharts
        ?.sort((a, b) => a.index - b.index)
        .map((chart, index) => {
          const uniqueKey = chart.spec
            ? `div_hist_plot_${chart.spec}`
            : `div_hist_plot_${index}`;

          if (
            chart.type === "oxoia.charts::HistorianChart" &&
            chart.dataSeries &&
            dataSets[index].length > 0
          ) {
            return (
              <ChartBlock
                key={uniqueKey}
                loadingState={loading || dataSets.length === 0 ? 0 : 1} // show skeleton
                blockSize={blockSize[index]}
                chartKey={`chartblock-children-${uniqueKey}`}
                title={chart.title}
                chartSpec={chart.spec}
                chartDefs={chart.dataSeries}
                chartData={dataSets[index]}
                chartDescriptions={chartDescriptions}
                parentContainerId={idContainer}
                divKey={`${idContainer}-${index}`}
                kpiDefs={sharedHeaderObject.chartViewData.kpis}
                kpiData={["45%", 5, "OK"]} // playground, needs api call
                chartViewAttr={sharedHeaderObject}
                id={`blockkey-${index}`}
              />
            );
          }

          return <div key={uniqueKey}>No data found</div>;
        })}
    </div>
  );
};
export default ChartGraphWorkspace;
