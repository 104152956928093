// DirectLinkHandler.js
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { fetchChartLinkData } from "../actions/api"; // Ensure this import path is correct
import Workspace from "../PageStructure/Workspace";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const DirectLinkHandler = () => {
    const { t } = useTranslation();
    const params = useParams(); // Extract the hash code from the URL
    const navigate = useNavigate(); // Used to navigate to the charts page
    const [errorMessage, setErrorMessage] = useState(""); // New state for error message
    const [loading, setLoading] = useState(true); // New state for error message

    console.log(useParams())
    useEffect(() => {
        const fetchLinkData = async () => {
            try {
                // Fetch the link data using the hash code
                const response = await fetchChartLinkData(params['*']);
                const { success, data } = response
                setLoading(false)
                console.log(data)
                if (success) {

                    // Store the data in session storage or state
                    sessionStorage.setItem("pageData", JSON.stringify(data.page_data));
                    // Redirect to the target page
                    navigate(data.target_page || "/charts");
                } else {
                    setErrorMessage(data);
                    setTimeout(x => {
                        navigate("/campuses");
                    }, 3000)
                }
            } catch (error) {
                setErrorMessage("Error fetching link data:", error);
                setTimeout(x => {
                    navigate("/campuses");
                }, 3000)
            } 
        };

        fetchLinkData();
    }, [params, navigate]);

    //   return <div>Loading chart configuration...</div>;
    return (
        <Workspace isLoading={loading} title={t("LOAD...")}>
            {errorMessage ? (
                <Typography variant="h6" color="error" align="center" sx={{ mt: 4 }}>
                    {errorMessage}
                </Typography>
            ) : (
                <>

                </>
            )}
        </Workspace>
    )
};

export default DirectLinkHandler;