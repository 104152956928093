import React, { useState } from 'react';

const YesNoYearSelector = ({getYear, type}) => {
    const [selection, setSelection] = useState(null);
    const [year, setYear] = useState('');

    const years = Array.from({ length: 124 }, (_, i) => 1900 + i); // Generates years from 1970 to 2019

    const handleSelectionChange = (event) => {
        setSelection(event.target.value);
    };

    const handleYearChange = (event) => {
        setYear(event.target.value);
        getYear(event.target.value, type)

    };

    return (
        <div>
            <h3>Do you agree?</h3>
            <label>
                <input 
                    type="radio" 
                    value="Yes" 
                    checked={selection === 'Yes'} 
                    onChange={handleSelectionChange} 
                />
                Yes
            </label>
            <label>
                <input 
                    type="radio" 
                    value="No" 
                    checked={selection === 'No'} 
                    onChange={handleSelectionChange} 
                />
                No
            </label>

            <h3>Select a Year</h3>
            <select value={year} onChange={handleYearChange}>
                <option value="">Select a year</option>
                {years.map((year) => (
                    <option key={year} value={year}>{year}</option>
                ))}
            </select>

  
        </div>
    );
};

export default YesNoYearSelector;
