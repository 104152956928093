import React, { useState } from 'react';
import {
    Card,
    CardContent,
    styled,
    IconButton,
    Popover,
} from '@mui/material';
import ReactMarkdown from 'react-markdown'
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from "react-i18next";

const StyledCardContent = styled(CardContent)(({ theme }) => ({
    maxHeight: 'auto', // Set a maximum height for the card content
    maxWidth: '100%',
    paddingTop: '0px',
    paddingBottom: '0px',
    overflow: 'auto', // Enable scrolling when content exceeds the max height
}));


/**
 * Card rendering markdown. Additional renderers available
 * See https://commonmark.org/help/
 * @param {String} text - The markdown text
 * @param {Number} drawerWidth - Margin on the lefs side (chart) 
 * @param {Number} descWidth - Width of card (as px)  
 *  * @param {Number} drawerWidth - Margin on the lefs side (chart) 
 *  */
const DescriptionCard = ({ text, blockSize }) => {
    const { t } = useTranslation();
    const showText = text === undefined ? t("NO_INFO_AVAILABLE_MARKDOWN") : text
    // console.log("TEST DescriptionCard",`${descWidth}px`,text,marginLeft, descWidth )
     //style={{ width: `${descWidth}px`, marginLeft: `${marginLeft}px` }} variant="outlined">
    return (
        <Card >
            <StyledCardContent >
                <ReactMarkdown >
                    {showText}
                </ReactMarkdown >
            </StyledCardContent>
        </Card>
    );
};


const Overlay = ({ onClose, anchorEl, text, blockSize }) => {
    const open = Boolean(anchorEl);
    
    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',

            }}

        >
            <DescriptionCard text={text} blockSize={blockSize}/>
        </Popover>
    );
};

/**
 * A component that utilizes uPlot to render historical data with performance optimizations using hooks and batched updates.
 * Based on example from https://codesandbox.io/p/sandbox/uplot-react-6ykeb
 * @param {function} text - The event handler function.
 * @param {Object} blockSize - Object with dimensions 
 * @param {Object} styling - Object with button styling
 *  */
const ChartDescription = ({ text, styling, blockSize }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    
    const handleFabClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null); // Reset the anchor element when the overlay is closed
    };

    return (
        <>
            <IconButton
                color="inherit"
                onClick={handleFabClick}
                size="small"
                className="header"
                sx={styling}
            >
                <InfoIcon />
            </IconButton>
            <Overlay anchorEl={anchorEl} onClose={handleClose} text={text} blockSize={blockSize}/> {/* Pass anchorEl and onClose to the Overlay */}
        </>
    );
}


export default ChartDescription;
