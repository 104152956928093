import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css"; // Import CSS
import { useTranslation } from "react-i18next";
import GenericAutocomplete from "../Generic/GenericAutocomplete";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useDrawerWidth } from "../../size";

import {
  fetchCustomerAccessibleCampus,
  fetchChartViews,
  fetchChartTargets,
} from "../actions/api";

const defaultChartHeaderObject = {
  // customer
  customerID: null,

  // campus
  campusId: null,
  campusHaystackId: null,
  campusTimeZone: null,

  // chartGroup
  selectedChartGroupLabel: null, // required for autocomplete
  selectedChartGroupData: [], // data for further processing
  // sub group
  selectedChartSubGroupLabel: null, // required for autocomplete
  selectedChartSubGroupData: [],

  selectedSubGroupTarget: null,
  //selectedChartDetails: {},

  selectedNavNames: [],

  targetIds: [], // target ids

  // others
  reset: false,
};

/**
 * Header alement with selectors for campus, main and subtopic
 * @param {Function} objectHandler - Function that collects targets from api call. Returns the object 'targetData' with an array empty or with data
 * @returns {JSX.Element} The rendered Autocomplete component
 *  */
const ChartHeader = ({ objectHandler }) => {
  const { t } = useTranslation();
  const drawerWidth = useDrawerWidth(); // if 0 => mobile

  const [allCampuses, setAllCampuses] = useState([]);
  const [selectedCampus, setSelectedCampus] = useState(() => {
    // object
    const saved = localStorage.getItem("selectedCampus");
    return saved === null || saved.length === 0
      ? null
      : JSON.parse(saved)
          .flat()
          .map((camp) => (camp ? { ...camp, label: camp.name } : {}))
          .slice(0, 1)[0];
  });

  const [chartHeaderObject, setChartHeaderObject] = useState(() => {
    const saved = localStorage.getItem("chartHeaderObject");
    const parsed = JSON.parse(saved);

    if (parsed === null && selectedCampus !== null)
      return {
        ...defaultChartHeaderObject,
        campusId: selectedCampus.id,
        campusHaystackId: selectedCampus.campus_haystack_model_id,
        campusTimeZone: selectedCampus.timezone,
      };
    else if (parsed !== null && selectedCampus !== null)
      return {
        ...parsed,
        campusId: selectedCampus.id,
        campusHaystackId: selectedCampus.campus_haystack_model_id,
        campusTimeZone: selectedCampus.timezone,
      };
    else if (parsed !== null && selectedCampus === null) return parsed;
    else return defaultChartHeaderObject;
  });

  useEffect(() => {
    localStorage.setItem(
      "chartHeaderObject",
      JSON.stringify(chartHeaderObject)
    );
  }, [chartHeaderObject]);

  // Update selectedCampus list in localStorage
  useEffect(() => {
    if (selectedCampus !== null) {
      let saved = localStorage.getItem("selectedCampus");
      let camps = saved ? JSON.parse(saved) : [];
      let existingObject = camps.find((obj) => obj.id === selectedCampus.id);
      if (!existingObject) {
        // replace item 0 or push if empty
        if (camps.length === 0) {
          camps.push(selectedCampus);
        } else {
          camps[0] = selectedCampus;
        }
        localStorage.setItem("selectedCampus", JSON.stringify(camps));
      }
    }
  }, [selectedCampus]);

  // Fetch campuses, updata allCampuses and header object
  useEffect(() => {
    const fetchData = async () => {
      try {
        const customerData = await fetchCustomerAccessibleCampus();
        if (customerData.campuses.length > 0) {
          const processedCampuses = customerData.campuses?.map((campus) => ({
            ...campus,
            sites: campus.sites.map((site) => ({
              ...site,
              campus_id: campus.id,
            })),
          }));
          setAllCampuses(processedCampuses);
          setChartHeaderObject((prevChartObject) => ({
            ...prevChartObject,
            // this is updated only here
            customerID: customerData.customer_id,
          }));
        } else {
          console.error(t("No campuses available for this user."));
        }
      } catch (error) {
        console.error(t("Error fetching campuses data."));
      }
    };
    fetchData();
  }, [t]);

  const organizedChartData = async (rawDataPromise) => {
    const rawData = await rawDataPromise;
    const uniqueChartGroupNames = [
      ...new Set(rawData.map((item) => item.chartGroup)),
    ];

    const result = uniqueChartGroupNames.map((name) => {
      const elementsOfSameGroup = rawData.filter((f) => f.chartGroup === name);
      return {
        chartGroupName: name,
        subGroups: elementsOfSameGroup,
      };
    });

    return { chartData: result };
  };

  // UPdate chart view templates
  useEffect(() => {
    const fetchData = async () => {
      if (chartHeaderObject.customerID && chartHeaderObject.campusId) {
        const chartData = await organizedChartData(
          fetchChartViews(
            chartHeaderObject.customerID,
            chartHeaderObject.campusId
          )
        );
        console.info("ChartHeader chartData", chartData);
        setChartHeaderObject((prevChartObject) => ({
          ...prevChartObject,
          ...chartData, // chartData: [..], adds array of objects with chartGroupNam and subGroups
        }));
      }
    };
    fetchData();
  }, [chartHeaderObject.customerID, chartHeaderObject.campusId]);

  // Update targets
  useEffect(() => {
    const fetchData = async () => {
      const oxoia_command = `${chartHeaderObject.selectedSubGroupTarget},${chartHeaderObject.campusHaystackId}`;

      const targets = await fetchChartTargets(
        chartHeaderObject.customerID,
        chartHeaderObject.campusId,
        oxoia_command
      );

      setChartHeaderObject((prevChartObject) => ({
        ...prevChartObject,
        targetData: targets,
      }));
      // Add all data requiered by other components ()

      objectHandler({
        targetData: targets,
        chartViewData: {
          customerID: chartHeaderObject.customerID,
          campusId: chartHeaderObject.campusId,
          searchFunc: chartHeaderObject.selectedChartSubGroupData.searchFunc,
          targetSpec: chartHeaderObject.selectedChartSubGroupData.target,
          charts: chartHeaderObject.selectedChartSubGroupData.charts,
          kpis: chartHeaderObject.selectedChartSubGroupData.kpis,
          campusHaystackId: chartHeaderObject.campusHaystackId,
          campusTimeZone: chartHeaderObject.campusTimeZone,
        },
      });
    };
    if (
      chartHeaderObject.customerID &&
      chartHeaderObject.selectedSubGroupTarget &&
      chartHeaderObject.campusId &&
      chartHeaderObject.campusHaystackId
    ) {
      fetchData();
    }
  }, [
    chartHeaderObject.campusId,
    chartHeaderObject.campusHaystackId,
    chartHeaderObject.selectedSubGroupTarget,
    chartHeaderObject.customerID,
    chartHeaderObject.campusTimeZone,
    chartHeaderObject.selectedChartSubGroupData.charts,
    chartHeaderObject.selectedChartSubGroupData.kpis,
    chartHeaderObject.selectedChartSubGroupData.searchFunc,
    chartHeaderObject.selectedChartSubGroupData.target,
    objectHandler,
  ]);

  const handleSelectCampus = (val) => {
    if (val !== null && val !== undefined) {
      setChartHeaderObject({
        ...chartHeaderObject,
        campusId: val.id,
        campusHaystackId: val.campus_haystack_model_id,
        campusTimeZone: val.timezone ? val.timezone : "Europe/Zurich",

        // resets
        // selectedChartSubGroupLabel: null,
        // selectedChartSubGroupData: [],
        // selectedSubGroupTarget: null,
        // selectedSubGroupSearchFunc: null,
        // selectedSubGroupChartType: null,
        // selectedSubGroupCharts: [],
        // selectedSubGroupKpis: [],
        reset: true,

        targetData: [],
      });
      objectHandler({ targetData: [], chartViewData: {} });
      setSelectedCampus(val); // object
    }
  };

  const handleSelectChartGroup = (group) => {
    if (group !== null && group !== undefined) {
      setChartHeaderObject({
        ...chartHeaderObject,
        selectedChartGroupLabel: group.label,
        selectedChartGroupData:
          chartHeaderObject.chartData.find(
            (f) => f.chartGroupName === group.label
          )?.subGroups || [],

        // reset all subgroup data
        selectedChartSubGroupLabel: null,
        selectedChartSubGroupData: [],
        selectedSubGroupTarget: null,
        selectedSubGroupSearchFunc: null,
        selectedSubGroupChartType: null,
        selectedSubGroupCharts: [],
        selectedSubGroupKpis: [],
        reset: true,
        targetData: [],
      });
      objectHandler({ targetData: [], chartViewData: {} });
    }
  };

  const handleSelectChartSubGroup = (subgroup) => {
    if (subgroup !== null && subgroup !== undefined) {
      const selectedDataElement = chartHeaderObject.selectedChartGroupData.find(
        (f) => f.chartSubGroup === subgroup.label
      );
      setChartHeaderObject({
        ...chartHeaderObject,
        selectedChartSubGroupLabel: subgroup.label,
        selectedChartSubGroupData: selectedDataElement || {},
        selectedSubGroupTarget: selectedDataElement?.target || null,
        selectedSubGroupSearchFunc: selectedDataElement?.searchFunc || null,
        selectedSubGroupChartType: selectedDataElement?.chartType || null,
        selectedSubGroupCharts: selectedDataElement?.charts || [],
        selectedSubGroupKpis: selectedDataElement?.kpis || [],
        reset: true,
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: `${drawerWidth === 0 ? "column" : "row"}`,
        alignItems: "center",
      }}
    >
      {/* Show only first selected item */}
      <GenericAutocomplete
        multiple={false}
        dataList={allCampuses}
        changeHandler={handleSelectCampus}
        value={selectedCampus !== null ? selectedCampus : null}
        size={`${drawerWidth === 0 ? "small" : "medium"}`}
        style={{
          paddingBottom: `${drawerWidth === 0 ? "0px" : null}`,
          minWidth: `${drawerWidth === 0 ? "100%" : "150px"}`,
          width: `${drawerWidth === 0 ? "100%" : "350px"}`,
        }}
        label={t("Campus")}
      />
      {drawerWidth > 100 && (
        <div
          style={{
            paddingBottom: "1rem",
            paddingLeft: "1rem",
            paddingRight: "1rem",
          }}
        >
          <NavigateNextIcon />
        </div>
      )}
      {drawerWidth > 0 && drawerWidth < 100 && (
        <div
          style={{
            paddingBottom: "1rem",
            paddingLeft: "0.2rem",
            paddingRight: "0.2rem",
          }}
        >
          <NavigateNextIcon />
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: `${drawerWidth === 0 ? "100%" : "66%"}`,
        }}
      >
        <GenericAutocomplete
          dataList={(chartHeaderObject.chartData || []).map(
            (item) => item.chartGroupName
          )}
          changeHandler={handleSelectChartGroup}
          size={`${drawerWidth === 0 ? "small" : "medium"}`}
          value={chartHeaderObject.selectedChartGroupLabel}
          label={t("CHARTCAT")}
          popperStyle={{
            width: `${drawerWidth === 0 ? "300px" : "auto"}`,
          }}
          style={{
            paddingBottom: `${drawerWidth === 0 ? "0px" : null}`,
            paddingRight: `${drawerWidth === 0 ? "1rem" : null}`,
            minWidth: `${drawerWidth === 0 ? "50%" : "150px"}`,
            width: `${drawerWidth === 0 ? "50%" : "350px"}`,
          }}
        />
        {drawerWidth > 100 && (
          <div
            style={{
              paddingBottom: "1rem",
              paddingLeft: "1rem",
              paddingRight: "1rem",
            }}
          >
            <NavigateNextIcon />
          </div>
        )}
        {drawerWidth > 0 && drawerWidth < 100 && (
          <div
            style={{
              paddingBottom: "1rem",
              paddingLeft: "0.2rem",
              paddingRight: "0.2rem",
            }}
          >
            <NavigateNextIcon />
          </div>
        )}
        <GenericAutocomplete
          dataList={(chartHeaderObject.selectedChartGroupData || []).map(
            (subGroup) => subGroup.chartSubGroup
          )}
          changeHandler={handleSelectChartSubGroup}
          size={`${drawerWidth === 0 ? "small" : "medium"}`}
          value={chartHeaderObject.selectedChartSubGroupLabel}
          label={t("CHARTCATSUB")}
          popperStyle={{
            placement: `${drawerWidth === 0 ? "left" : "bottom"}`,
            width: `${drawerWidth === 0 ? "300px" : "auto"}`,
          }}
          style={{
            paddingBottom: `${drawerWidth === 0 ? "0px" : null}`,
            minWidth: `${drawerWidth === 0 ? "50%" : "150px"}`,
            width: `${drawerWidth === 0 ? "50%" : "350px"}`,
          }}
        />
      </div>
    </div>
  );
};

export default ChartHeader;
