import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  TextField,
  Popper
} from "@mui/material";
import { styled } from '@mui/material/styles';

/**
 * Generic autocomplete field for large and small screens
 * @param {Array} dataList - List with elements (objects or strings, both possible)
 * @param {Function} changeHandler - Function that handles onChange callback, returns selected value
 * @param {String/Array} value - Actual selection, presented in field, with multiple selection, this must be an Array
 * @param {Object} style - Styling props, handed over to sx field of Autocomplete
 * @param {Object} popperStyle - Styling props for popper, set 'placement' and 'width'
 * @param {String} size - String for Autocomplete property 'size', 'small' or 'medium'
 * @param {String} label - Lable for text field (user help)
 * @param {String} value - Actual selection, presented in field
 * @param {Boolean} multiple - Multiple selection 
 * @returns {JSX.Element} The rendered Autocomplete component
 *  */
const GenericAutocomplete  = ({ dataList, changeHandler, value, style, size, label, popperStyle={}, multiple=false }) => {

  // console.log("xxxxxxxxxxxxxxxxxx",popperStyle, dataList,  value, style, size, label)
  const [all, setAll] = useState([]);

  useEffect(() => {
    if (dataList !== undefined && dataList !== null) {
      // test if dataList is array of object or an array of strings
      const isArrayOfStrings = dataList.every(item => typeof item === 'string');
      var mod = []
      if (isArrayOfStrings) {
        mod = dataList.map((val) => {
          var x = {}
          x["label"] = val
          return x
        })
      }
      else {
        mod = dataList.map((campus) => {
          campus["label"] = campus.name
          return campus
        })
      }
      setAll(mod)


    }
  }, [dataList]);

  // Increase the poper with, where necessary
  const StyledPopper = styled(Popper)({
    '& .MuiAutocomplete-listbox': {
      width: popperStyle.width, 
    },
    '& .MuiAutocomplete-popper': {
      width: popperStyle.width, 
    },
    '& .MuiAutocomplete-paper': {
      width: popperStyle.width, 
    },

  });
// Placement options
//   "bottom-start" (default)
// "bottom"
// "bottom-end"
// "top-start"
// "top"
// "top-end"
// "left-start"
// "left"
// "left-end"
// "right-start"
// "right"
// "right-end"
  const PopperWithPlacement = (props) =>  {
    return (<StyledPopper {...props}  placement={popperStyle.placement}/>)
  }

  return (
    <Autocomplete
      disablePortal
      multiple={multiple} 
      //getOptionLabel={(option) => option.name}
      id={(all.length === 0) ? "noId" : all[0].label}
      size={size}
      options={all}
      PopperComponent={PopperWithPlacement}
      onChange={(event, newValue) => {
        // return object  
        changeHandler(newValue);
      }}
      value={value}
      sx={{
        ...style,
        borderColor: "green",
        marginBottom: "1rem",
        '& input': {
          width: 200,
          bgcolor: 'background.paper',
          color: (theme) =>
            theme.palette.getContrastText(theme.palette.background.paper),
        },
      }}
      renderInput={(params) => <TextField className="selectorAuto" {...params} key={label} label={label} />}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      
    />
  );
};

export default GenericAutocomplete ;