import React, { useState, useEffect } from "react";
import Stack from '@mui/material/Stack';
import { RangePicker } from "../Generic/RangePicker"
import { useDrawerWidth } from '../../size';
import ChartSelectRangeButton from "./ChartSelectRangeButton"



// Select date range
// Format object handler: { startDate: null, startTime: null, startDateTime: null, endDate: null, endTime: null, endDateTime: null }
const ChartDateRangeSelector = ({objectHandler}) => {

    const drawerWidth = useDrawerWidth()
   
    // in case, value is provided (nowhere written at the moment)
    const [selectedRange, setSelectedRange] = useState(() => { // str (haystack id)
        const saved = localStorage.getItem("selectedChartRange");
        return saved === null || saved.length === 0
            ? { rangeType:"custom" }
            : JSON.parse(saved)
    });
    // in case, value is provided (nowhere written at the moment)
    const [selectedSpan, setSelectedSpan] = useState(() => { // str (haystack id)
        const saved = localStorage.getItem("selectedChartSpan");
        return saved === null || saved.length === 0
            ? { startDate: null, startTime: null, startDateTime: null, endDate: null, endTime: null, endDateTime: null }
            : JSON.parse(saved)
    });

    // update data for parent
    useEffect(() => {
        
        const combinedObject = {
            ...selectedSpan,
            ...selectedRange
          };
           objectHandler(combinedObject,selectedSpan)
    }, [selectedRange,selectedSpan,objectHandler]);

    return (
        <div style={{ paddingTop: "1rem", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: drawerWidth === 0 ? 'space-between' : 'left', flexWrap: 'wrap' }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                <Stack
                    direction="row"
                    spacing={0.5}
                    style={{
                        paddingRight: "1rem", paddingBottom: "1rem",
                    }}>
                   
                    <ChartSelectRangeButton 
                        range={selectedRange} 
                        setRange={setSelectedRange}
                        setSpan={setSelectedSpan} 
                    />
                </Stack>
                <RangePicker disabled={selectedRange.rangeType !== "custom"} span={selectedSpan} setSpan={setSelectedSpan}/>


            </div>
        </div>
    );
}

export default ChartDateRangeSelector