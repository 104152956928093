import React, { useState } from 'react';
import {
    Card,
    CardContent,
    Typography,
    TextField,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Grid,
    Button,
} from '@mui/material';
import YesNoYearSelector from './CalculatorYesNo'

/*
1. Determine a saving range in % based on
    - age
    - use
    - size / complexity
2. Increase or reduce min/max/both values based on selection
3. Show potential in %, calculate saving in kWh and CO2eq

*/


// (set base value), siehe https://enbau-online.ch/bautechnik-der-gebaeudehuelle/1-3-gebaeudehuelle-seit-1900/
const savingRangeByAgeRelative = (year) => {
    const parsedYear = Number(year);
    const adjustments = [
        { start: -Infinity, end: 1969, adjustment: [5, 10] },
        { start: 1970, end: 1980, adjustment: [3, 7] },
        { start: 1981, end: 1990, adjustment: [2, 5] },
        { start: 1991, end: 2000, adjustment: [1, 3] },
        { start: 2001, end: 2010, adjustment: [1, 1] },
        { start: 2011, end: Infinity, adjustment: [1, 1.5] }
    ];

    const result = adjustments.filter(({ start, end }) => parsedYear >= start && parsedYear <= end);

    // consider renovation year of roof (30%) facade (30% ) / window (30% ) / cellar (10%)

    return result.length > 0 ? result[0].adjustment : [0, 0];
}

// Based on the use, the potential is higher (set base value)
const savingRangeByBuildingCategoryRelative = (category) => {
    const categoryAdjustments = {
        'Industrial': [5, 5],
        'Office / Residential': [1, 3],
        'Office / Warehouse': [5, 5],
        'Office': [5, 5],
        'Other': [2, 4],
        'Residential': [1, 3],
        'Retail / Office': [1, 2],
        'Retail': [5, 10],
        'Warehouse': [5, 10],
    };

    return categoryAdjustments[category] || [0, 0];
}

// The potential increases linear with the heating area (= complexity), (set base value)
const savingRangeByBuildingSizeRelative = (area) => {
    const parsedArea = Number(area);
    const adjustments = [
        { start: -Infinity, end: 1000, adjustment: [1, 3] },
        { start: 1000, end: 5000, adjustment: [3, 7] },
        { start: 5000, end: 10000, adjustment: [5, 10] },
        { start: 10000, end: Infinity, adjustment: [7, 13] }
    ];

    const matchingAdjustment = adjustments.filter(({ start, end }) => parsedArea >= start && parsedArea < end);

    return matchingAdjustment[0].adjustment
};

const summarizeRanges = (ranges) => {

    let sumA = 0;
    let sumB = 0;

    for (const [a, b] of ranges) {
        sumA += a;
        sumB += b;
    }

    return [sumA, sumB];
}

const base = [1, 1]

const Calculator = () => {


    const [selections, setSelections] = useState({
        baseRange: base,
        modRanage: [0, 0],
        constructionYear: 1990,
        buildingCategory: 'Residential',
        heatedArea: 500,
    });
    console.log(selections)

    // Dropdown list
    const buildingCategories = [
        'Industrial',
        'Office / Residential',
        'Office / Warehouse',
        'Office',
        'Other',
        'Residential',
        'Retail / Office',
        'Retail',
        'Warehouse',
    ];

    const constructionYears = [];
    for (let year = 1900; year <= 2024; year++) {
        constructionYears.push(year);
    }

    const heatingType = [
        'District Heating',
        'Electricity',
        'Heat Pump Air',
        'Heat Pump Earth',
        'Heating Oil',
        'Wood / Pellets',
        'Natural Gas',
    ];
    const domesticWater = [
        'Electric Boiler',
        'Heat Pump Boiler',
        'Oil/Gas/Wood/District Heating',
    ];
    const consumptionHeat = 0 // kWh, liter, kg wood
    const consumptionElec = 0 // kWh, liter, kg wood
    const airCon = ['yes', 'no'];

    // Renovations
    const renRoof = ['yes', 'no'];
    const renRoofYear = ['yes', 'no'];

    const renFacade = ['yes', 'no'];
    const renFacadeYear = ['yes', 'no'];

    const renCellar = ['yes', 'no'];
    const renCellarYear = ['yes', 'no'];

    const renWindows = ['yes', 'no'];
    const renWindowsYear = ['yes', 'no'];

    // Infrastructure
    const numberOfHeatingGroups = 1
    const numberOfVentilationSystems = 0
    const numberOfCoolingGroups = 0
    const yearAutomation = '1999'
    const lastEbo = 0

    const update = (val, type) => {
        if (type === "heatedArea") {

            setSelections((prevVal) => ({
                ...prevVal,
                [type]: val,
                baseRange: summarizeRanges([base, savingRangeByBuildingSizeRelative(val), savingRangeByAgeRelative(prevVal.constructionYear), savingRangeByBuildingCategoryRelative(prevVal.buildingCategory)])
            }));

        }
        if (type === "buildingCategory") {
            setSelections((prevVal) => ({
                ...prevVal,
                [type]: val,
                baseRange: summarizeRanges([base, savingRangeByBuildingSizeRelative(prevVal.heatedArea), savingRangeByAgeRelative(prevVal.constructionYear), savingRangeByBuildingCategoryRelative(val)])
            }));

        }
        if (type === "constructionYear") {
            setSelections((prevVal) => ({
                ...prevVal,
                [type]: val,
                baseRange: summarizeRanges([base, savingRangeByBuildingSizeRelative(prevVal.heatedArea), savingRangeByAgeRelative(val), savingRangeByBuildingCategoryRelative(prevVal.buildingCategory)])
            }));

        }
    }

    const getYear = (val, type) => {
        setSelections((prevVal) => ({
            ...prevVal,
            [type]: val,
            //baseRange: summarizeRanges([base, savingRangeByBuildingSizeRelative(prevVal.heatedArea), savingRangeByAgeRelative(val), savingRangeByBuildingCategoryRelative(prevVal.buildingCategory)])
        }));
    }

    return (
        <div style={{ padding: '20px' }}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                        <InputLabel id="dropdown1-label">Gebäudekategorie</InputLabel>
                        <Select
                            labelId="dropdown1-label"
                            value={selections.buildingCategory}
                            onChange={(e) => update(e.target.value, "buildingCategory")}
                        >
                            {buildingCategories.map((option, index) => (
                                <MenuItem key={index} value={option}>{option}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                        <InputLabel id="dropdown2-label">Erstellungsjahr</InputLabel>
                        <Select
                            labelId="dropdown2-label"
                            value={selections.constructionYear}
                            onChange={(e) => update(e.target.value, "constructionYear")}
                        >
                            {constructionYears.map((option, index) => (
                                <MenuItem key={index} value={option}>{option}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                    <YesNoYearSelector type={"facade"} getYear={getYear} />
                </Grid>

                {/*  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                        <InputLabel id="dropdown3-label">Dropdown 3</InputLabel>
                        <Select
                            labelId="dropdown3-label"
                            value={dropdown3}
                            onChange={(e) => setDropdown3(e.target.value)}
                        >
                            {dropdownOptions3.map((option, index) => (
                                <MenuItem key={index} value={option}>{option}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid> */}
            </Grid>
            <Grid container spacing={3} style={{ marginTop: '20px' }}>
                <Grid item xs={12} md={4}>
                    <TextField
                        label="Heating area in m2"
                        variant="outlined"
                        fullWidth
                        value={selections.heatedArea}
                        onChange={(e) => update(e.target.value, "heatedArea")}
                    />
                </Grid>
                {/* <Grid item xs={12} md={4}>
                    <TextField
                        label="Input 2"
                        variant="outlined"
                        fullWidth
                        value={input2}
                        onChange={(e) => setInput2(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        label="Input 3"
                        variant="outlined"
                        fullWidth
                        value={input3}
                        onChange={(e) => setInput3(e.target.value)}
                    />
                </Grid> */}
            </Grid>
            {/* <Grid container spacing={3} style={{ marginTop: '20px' }}>
                <Grid item xs={12}>
                    <Button variant="contained" onClick={handleCalculate}>
                        Calculate
                    </Button>
                </Grid>
            </Grid> */}

            <Card style={{ marginTop: '20px' }}>
                <CardContent>
                    <Typography variant="h5" component="h2">
                        Saving potantial (base range)
                    </Typography>
                    <Typography variant="h1" color="textSecondary">
                        {selections.baseRange[0]}% to {selections.baseRange[1]}%
                    </Typography>
                    <Typography variant="h5" component="h2">
                        Saving potantial (component range)
                    </Typography>
                    <Typography variant="h1" color="textSecondary">
                        {selections.baseRange[0]}% to {selections.baseRange[1]}%
                    </Typography>
                </CardContent>
            </Card>

        </div>
    );
};

export default Calculator;
