import React from "react";
import {
    Typography,
    Paper,
} from "@mui/material";
import HistorianUPlot from "./HistorianUPlot";
import {
    getNightSpanTimestamps,
} from './uplotFuncs'
import {
    useTheme,
} from "@mui/material";



/**
 * Represent a chart with a header bar and 0..3 Kpi boxes and description
 * @param {Str} title - The event handler function.
 * @param {Object} chartDefs - Object with complete chartview (including all charts, KPIs) definition. 
 * @param {Array} kpiDefs - Object with complete chartview (including all charts, KPIs) definition. 
 * @param {Array} chartData - The data array
 * @param {Array} kpiData - The data array
 * @param {Number} drawerWidth - With of the menu drawer incl. margin
 * @param {Str} text - Description text
 */
const ChartBlock = ({ blockSize, title, chartSpec, chartDefs, chartData, chartDescriptions, kpiDefs, kpiData, chartKey, chartViewAttr, divKey }) => {

    const { kpi, parent } = blockSize
    const { kpiHeight } = kpi
    const { titleHeight } = parent
    console.log(chartDefs, chartSpec)
    const theme = useTheme();

    // eslint-disable-next-line no-unused-vars
    const KpiDisplay = ({ val, id }) => {

        const margin = 20
        return (
            <div key={`typo-${chartKey}`} style={{ boxSizing: 'border-box', paddingBottom: `${margin}px`, paddingLeft: `${margin}px`, width: '100%' }}>
                <Paper elevation={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: kpiHeight }}>
                    <Typography variant={(kpiHeight < 80) ? "h6" : "h5"} component="h2">
                        {val}
                    </Typography>
                </Paper>
            </div>
        )
    }

    const ChartSelectMemo = React.memo(({ childKey, data, attr, divK }) => {
        return <ChartSelect childKey={`a-a-${childKey}`} key={`a-a-${childKey}`} data={data} attr={attr} divK={divK} />;
    });


    const splitBoolNumberData = (_defs, _data) => {

        // Get timestamps for night shades
        // TODO Use apie to get sunset for this location
        const dateWithTimestamps = getNightSpanTimestamps({ sunset: { hour: 19, min: 0 }, sunrise: { hour: 7, min: 0 }, data: _data, tz: chartViewAttr.chartViewData.campusTimeZone });

        return {
            origData: _data,
            origChartDefs: _defs,
            dateWithTimestamps: dateWithTimestamps
        }
    }


    const ChartSelect = ({ data, attr, divK, childKey }) => {

        // make sure data size fits opts (asynch processes), wait until true
        if (data.length !== attr.points.length + 1) {
            // TODO Remove this if as soon as api is fixed
            if (data.length > 10) data = data.splice(attr.points.length + 1)
            else return
        }


        return (
            <HistorianUPlot
                childKey={childKey}
                syncKey={data[0][0]} // take first ts
                tz={chartViewAttr.chartViewData.campusTimeZone}
                defsAndData={splitBoolNumberData(attr, data)}
                chartSpec={chartSpec}
                chartDescriptions={chartDescriptions}
                title={""}
                data={data}
                divK={divK}
                blockSize={blockSize} // Total block width (max)
            />
        )


    }

    return (
        <div
            id="subGraphContainer"
            style={{
                marginBottom: '1rem',
                width: '100%',
                display: "flex",
                flexDirection: "column",
                alignItems: 'start',
                justifyContent: 'left',

            }}
            key={`subKey-${chartKey}`}
        >
            <Typography
                variant="h6"
                component="h2"
                style={{
                    width: '100%',
                    height: titleHeight,
                    // border: `1px solid ${theme.palette.secondary.dark}`,
                    backgroundColor: theme.palette.secondary.light,
                    color: theme.palette.secondary.contrastText,
                    borderRadius: 6,
                    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
                    textAlign: 'center',

                    padding: '2px',
                    textTransform: 'uppercase'
                }}
            >
                {title}
            </Typography>
            
            <div style={{ width: blockSize.parent.width, height: blockSize.parent.height, marginTop: "20px", display: "flex", flexDirection: "row", alignItems: 'start', justifyContent: 'left' }}>
                <div key={`a-${chartKey}`} id="subGraphContainerChart" style={{ width: blockSize.parent.width, height: blockSize.parent.height, display: "flex", flexDirection: "row", alignItems: 'start', justifyContent: 'left' }}>
                    <ChartSelectMemo childKey={`aa-${chartKey}`} key={`aa-${chartKey}`} data={chartData} attr={chartDefs} divK={divKey} />
                </div>
                <Paper elevation={0} key={`b-${chartKey}`} style={{ boxSizing: "border-box", width: blockSize.kpi.kpiWidth, height: blockSize.parent.height, display: "flex", flexDirection: "column", alignItems: 'start', justifyContent: 'center' }}>
                    {kpiDefs !== undefined && kpiDefs.map((kpi, index) => {
                        return (
                            <div key={`bb-${chartKey}-${index}`}>
                                
                                {/* <KpiDisplay childKey={`bb-child-${chartKey}-${index}`} key={`bb-${chartKey}-${index}`} id={index} val={kpiData[index]} /> */}

                            </div>
                            )

                    })}
                </Paper>
            </div>

        </div>
    );
}
export default ChartBlock;
