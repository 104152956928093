/**
 * Calculate all sizes for a Chartblock, depending on size of parent element and side or bottom menu
 * See https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
 * @param {String} sizeDefiningId - ID of parent div
 * @param {Number} drawerWidth - Width of side menu. If 0 => mobile
 * @param {Object} chartDefs - Object with chart definitions
 *  */
export const getChartBlockSizes = (sizeDefiningId, drawerWidth, chartDefs) => {
    console.log("block", drawerWidth)
    const parentElement = document.getElementById(sizeDefiningId);
    const widthPercChartBlockPercentage = 85 // %
    const widthWorkspace = parentElement.offsetWidth
    const workspaceLegendSplit = 900
    const relationFactor = (widthWorkspace < workspaceLegendSplit) ? 1 : 3.25
    const numberOfPoints = chartDefs ? chartDefs.points.length : 0
    // const heightPerPointChart = (drawerWidth === 0) ? 50 : 100
        
    // legend requires around 320px if marker is ommitted (border-box => includes padding and reduces content)
    const legendWidth = (widthWorkspace <= workspaceLegendSplit) ? 0 : Math.min((widthWorkspace/workspaceLegendSplit-0.3)*320, 320)
    const legendHeightPerPoint = (widthWorkspace <= workspaceLegendSplit) ? 0 : 48
    const legendMarginPerPoint = (widthWorkspace < workspaceLegendSplit) ? 0 : 2
    const legendWidthLabel = (widthWorkspace < workspaceLegendSplit) ? 0 : 0.75*legendWidth
    const legendWidthMarker = (widthWorkspace < workspaceLegendSplit) ? 0 : 0
    const legendFontSize = Math.min(16, Math.max((widthWorkspace / workspaceLegendSplit - 0.2) * 12, 12))
    const legendWidthValue = (widthWorkspace < workspaceLegendSplit) ? 0 : legendWidth - legendWidthLabel - legendWidthMarker
    const legendTotalHeight = (numberOfPoints + 1) * legendHeightPerPoint + numberOfPoints*legendMarginPerPoint
    
    // toolbar
    const toolbarWidth = (widthWorkspace < workspaceLegendSplit) ? 40 : 40

    // Charts
    const chartWidth = widthWorkspace * widthPercChartBlockPercentage/100 - toolbarWidth - legendWidth
    const chartHeight = Math.max(legendTotalHeight,chartWidth / relationFactor)
    // Kpis
    const kpiWidth = widthWorkspace * (100 - widthPercChartBlockPercentage)/100
    const maxNumberKpis = 3
    const kpiHeight = chartHeight / 3

    return {
        legend: {
            legendWidth: legendWidth,
            legendHeightPerPoint: legendHeightPerPoint,
            legendMarginPerPoint: legendMarginPerPoint,
            legendWidthLabel: legendWidthLabel,
            legendWidthMarker:legendWidthMarker,
            legendWidthValue:legendWidthValue, 
            legendTotalHeight:legendTotalHeight,
            legendFontSize:legendFontSize
        },
        chart: {
            chartWidth:chartWidth,
            chartHeight:chartHeight
        },
        toolbar: {
            toolbarWidth:toolbarWidth
        },
        kpi: {
            kpiWidth:kpiWidth,
            maxNumberKpis:maxNumberKpis,
            kpiHeight:kpiHeight
        },
        parent: {
            width:chartWidth+toolbarWidth+legendWidth,
            height:chartHeight,
            titleHeight: '35px',
            workspaceWidth: widthWorkspace,
            split:workspaceLegendSplit,
            drawerWidth:drawerWidth
        },
    };
};

/**
 * Converts a hex string with 3 or 6 letters to an rgb(a,b,c,alpha) format
 * @param {String} hex - Color in hex format
 * @param {Number} alpha - Alpha value
 *  */
export const hexToRgba = (hex, alpha = 1) => {
    
    hex = hex.replace(/^#/, '');

    // Parse r, g, b values
    let r, g, b;

    // If the hex is 3 digits (e.g., #fff), convert it to 6 digits (e.g., #ffffff)
    if (hex.length === 3) {
        r = parseInt(hex[0] + hex[0], 16);
        g = parseInt(hex[1] + hex[1], 16);
        b = parseInt(hex[2] + hex[2], 16);
    } else {
        r = parseInt(hex.slice(0, 2), 16);
        g = parseInt(hex.slice(2, 4), 16);
        b = parseInt(hex.slice(4, 6), 16);
    }

    // Return the RGBA string
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

// Test function
export const generateTestData = (numDataPoints, numSeries) => {

    const data = [];

    // Calculate the start and end timestamps (5 months ago to now)
    const fiveMonthsAgo = new Date(Date.now() - 5 * 30 * 24 * 60 * 60 * 1000);
    const now = new Date();

    // Generate random timestamps in epoch milliseconds between the start and end timestamps, and sort them
    const timestamps = Array.from({ length: numDataPoints }, () => {
        const randomTime = fiveMonthsAgo.getTime() + Math.random() * (now.getTime() - fiveMonthsAgo.getTime()) / 1000;
        return Math.floor(randomTime);
    }).sort((a, b) => a - b);
    // Generate random values for each data series
    for (let i = 0; i < numSeries; i++) {
        const seriesData = Array.from({ length: numDataPoints }, () => Math.floor(Math.random() * 50));
        data.push(seriesData);
    }

    // Combine timestamps and data series
    data.unshift(timestamps);
    return data;
}